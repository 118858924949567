import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Container from '../../global/container'
import styled from 'styled-components'
import dots from "../../../images/highlights/yellow_dots3.svg"

const DivStyled = styled.div`
  /* background: url(${dots}); */
  /* background-repeat: repeat; */
`



export default function Audience() {
  return (
    <div className='bg-grey-100'> 
    <DivStyled>
      <div className='py-10 md:py-20 '>
        <Container>
          
          <h2 className="text-slate-900 text-2xl md:text-4xl text-center">Is this course for me?</h2>
          
          <div className='md:w-3/4 mx-auto'>
            <p className="text-slate-900 text-sm md:text-base lg:text-lg lg:text-xl md:text-xl pt-5 text-gray-800 leading-relaxed w-full">The course is for anyone who wants to learn ggplot2 from the ground up and for those who look for a single course that covers all the fundamental concepts of ggplot2.</p>
          </div>

          <div className="flex flex-row flex-wrap md:flex-nowrap py-8 md:py-10 md:w-3/4 mx-auto">
            <div className="w-full md:w-7/12 md:mr-5 border-t-8 border-yellow-400 bg-white shadow p-5 rounded-md" >
              <p className="text-gray-900 text-base md:text-lg py-3"><span className="font-bold">Students</span> interested in creating visualizations for their theses</p>
              <p className="text-gray-900 text-base md:text-lg py-3"><span className="font-bold">Data scientists </span> interested in learning how to create visualizations in ggplot2 fast and effectively</p>
              <p className="text-gray-900 text-base md:text-lg py-3"><span className="font-bold">Data journalists</span> who want to create print-ready visualizations</p>
              <p className="text-gray-900 text-base md:text-lg py-3"><span className="font-bold">Scientists</span> who need to create data visualizations on a daily basis</p>
            </div>

            <div className="w-full md:w-5/12 mt-5 md:mt-0 md:ml-5 border-t-8 border-pink-400 bg-white shadow p-5 rounded-md" >
              <p className="text-gray-900 text-base md:text-lg py-3 font-bold">You should ...</p>

              <p className="text-gray-900 text-base md:text-lg py-3">... be able to to run scripts in R and to set up a project in R-Studio.</p>

              <p className="text-gray-900 text-base md:text-lg py-3">...  have some basic tidyverse skills. Basic skills are enough, you don't have to be a tidyverse superhero. </p>
            </div>
          </div>

          <div className="w-full md:w-96 text-center mx-auto ">
            <p className="text-grey-500 text-sm md:text-base pt-3 leading-relaxed">Still undecided if this course is for you? Don't worry, Udemy offers a 30-Day Money-Back Guarantee.</p>
          </div>
        

        </Container>
        </div>
      </DivStyled>
    </div>
  )
}

