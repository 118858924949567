import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Container from '../../global/container'



export default function Author() {
  return (
    <Container>
      <div className="flex flex-col md:flex-row py-10 justify-between md:w-3/4 mx-auto">

        <div className="w-full md:w-4/12 mb-5 md:mb-0 flex flex-row justify-center">
          <StaticImage className="rounded-full h-24 md:h-44 w-24 md:w-44 border-2 md:border-8" src={"../../../images/christian_burkhart.png"} alt="Christian Burkhart" objectFit="contain" width={200} height={200} />
        </div>

        <div className="w-full md:w-8/12 text-sm md:text-base">
          <h2 className="mb-3">Hi, I am Christian and created this course!</h2>
          <p className="mb-3">I am an Instructional Designer by training and a self-trained Data Scientist. I currently live in Munich, Germany, and work as a Senior Instructional Designer for an artificial intelligence company. I spend most of my time creating educational materials.</p>

          <p className="mb-3">My journey to data and programming began 10 years ago. Since I was a student I was passionate about R and had fun working with data. Over the years, I became particularly passionate in data visualization and the ggplot2 package. During my PhD ggplot2, the tidyverse and web development became my bread and butter and I gained extensive experience in the R environment.</p>

          <p className="mb-3">My goal is to make you a better data scientist. See you around!</p>
        </div>
      </div>
    </Container>

  )
}

