import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Container from '../../global/container'
import styled from 'styled-components'
import dots from "../../../images/highlights/yellow_dots2.svg"
import CourseCard from './coursecard'

const DivStyled = styled.div`
  margin-bottom: -60px;
`

const Pattern = styled.div`
  background: url(${dots});
  background-repeat: repeat;
`

export default function Hero() {
  return (
    <div className="bg-gradient-to-br from-yellow-200 to-yellow-400 z-10 mb-20">
      <Pattern>
      <Container>
        <div className='z-30 flex flex-col md:flex-row'> 
          <div className='py-10 md:py-20 w-full md:w-7/12'>
          
            <StaticImage className="pb-5 w-48 md:w-96" imgClassName="py-5" src="../../../images/logos/logoggplot2fundamentals.png" alt="logo" objectFit="contain" placeholder='none' />
            
            <p className="text-xl md:text-3xl pt-5 text-gray-800 ledaing-relaxed w-full">A complete training course that helps you create data visualizations in ggplot2</p>

            <div className="flex flex-row items-center py-5 md:py-10">
              <StaticImage className="py-2 rounded-full h-12 md:h-14 w-12 md:w-14 mr-3" src="../../../images/christian_burkhart.png" alt="Christian Burkhart"  placeholder='none' />
              <p className="text-sm md:text-base">Taught by <span className="font-bold">Christian Burkhart</span></p>
            </div>

          </div>

          <DivStyled className="w-full md:w-4/12 h-auto md:self-end flex flex-row justify-center">
            <CourseCard className="" />
          </DivStyled>
          
          
        </div>
      </Container>
      </Pattern>
    </div>
  )
}

