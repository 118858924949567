import React from 'react';
import Container from '../../global/container'
import styled from 'styled-components'
import pattern from "../../../images/highlights/grid3.svg"
import { StaticQuery, graphql } from "gatsby"

const DivStyled = styled.div`
  /* background: url(${pattern}); */
`


const Module = (props) => {
  return(
    <div className="w-100 bg-white rounded-md my-10 mr-5 pyb-5 border-t-8 border-yellow-500">
      <div className="bg-grey-50 rounded-md border-b border-gray-200">
        {/*  */}

        <div className="flex flex-row items-end bg-yellow-200 py-2 px-5 rounded">
          <p className="text-gray-600 font-light text-sm md:text-base mr-3">Module {props.modulekey}</p>
          <h3 className="text-base md:text-xl">{props.data.name}</h3>
        </div>
        
        <p className="py-4 px-5 w-100 md:w-3/4 text-sm md:text-base text-gray-800">{props.data.information}</p>
      </div>

      <div className="py-5">
        {props.data.lessons.map((e, i) => {
          return(
            <div key={i} className="bg-white px-5 py-1 md:py-2 flex flex-row">
              <span className="font-bold text-gray-500 pr-3 w-7 text-sm md:text-base">{i + 1}</span><br />
              <span className="pr-3 text-sm md:text-base">{e.title}</span>
              <span className="text-gray-500 text-sm md:text-base">{e.duration} min</span>
            </div>
          )
        })}
      </div>

     </div>
  )
} 

export default function Curriculum() {
  return(
    <StaticQuery
        query={graphql`
          query {
            curriculum: allFile(filter: {absolutePath: {regex: "/modules/"}}) {
              edges {
                node {
                  childrenYaml {
                    id
                    name
                    information
                    lessons {
                      title
                      duration
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className='bg-grey-800'>
          <DivStyled className='py-10 md:py-20'> 
            <Container>
              <h2 className="text-center"><span className="text-base font-bold text-slate-300">The</span><br /><span className="text-slate-50 text-2xl md:text-4xl">Curriculum</span></h2>

              <div className='md:w-3/4 mx-auto'>
                <p className="text-slate-50 text-sm md:text-base lg:text-lg pt-5 text-gray-800 leading-relaxed w-full">The curriculum covers all the fundamental concepts you need to create 90% of your visualizations.We will start with the most important concept, aesthetic mappings. We will then learn how to create the most basic plots. Once you are able to create these plots, we will discuss common pitfalls that beginners to ggplot2 often run into. In the next modules, we will learn how to customize aesthetic mappings with scales, how to create multiple plots by faceting, how to calculate summary statistics, and how to change the theme of your plots. Finally, we will give you some tips and tricks that everyone learning ggplot2 should know. Along the way, we will also create four best practice visualizations that cover all of the fundamental concepts we learn in this course. </p>

                <div className="">
                  {/* {console.log(data.curriculum.edges[0].node)} */}
                  {data.curriculum.edges[0].node.childrenYaml.map((e, i) => {
                    return(<Module key={i} data={e} modulekey={i + 1} />)
                  })}
                </div>

              </div>
            </Container>
          </DivStyled>
          </div>
        )}
      />
  )
}
