import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Container from '../../global/container'
import styled from 'styled-components'

const DivStyled = styled.div`
  span{
    font-family: "SF Fourche"
  }

  span:nth-child(2) {
    font-family: "Open Sans"
  }
`

export default function Approach() {
  return (
    <div className='py-10 md:py-20'> 
      <Container>
        <h2 className="text-center"><span className="text-base font-bold text-gray-700">Become a</span><br /><span className="text-gray-900 text-2xl md:text-4xl">ggplot2 expert</span></h2>

        <div className='md:w-3/4 mx-auto text-center pb-5 md:pb-10'>
          <StaticImage className="py-2" src="../../../images/highlights/hline2.svg" alt="underline" objectFit="contain" width={500} height={40} />
        </div>
        

        <div className='md:w-3/4 mx-auto'>
          <p className="text-lg md:text-xl md:text-xl pt-5 text-gray-800 leading-relaxed w-full">ggplot2 has a great community of people who have created a huge amount of learning materials. Finding the "good stuff" and putting them into a coherent personal curriculum, however, is hard. What makes it even harder is that you won't find any materials for some of the most crucial concepts on the internet (e.g., aesthetics for geometric objects).</p>

          <p className="text-lg md:text-xl md:text-xl pt-5 text-gray-800 leading-relaxed w-full">And there are many concepts to learn. You need to become familiar with data types, geometric objects, aesthetics, aesthetic mappings, faceting, scales, summary statistics, and themes. </p>

          <p className="text-lg md:text-xl md:text-xl pt-5 text-gray-800 leading-relaxed w-full">This training course covers all these fundamental concepts of ggplot2 in one place!  Once you have taken this course you will be able to create almost any data visualization in ggplot2 with ease. Here is what you'll get from the course:</p>
        </div>

        <div className="grid grid-cols-1 grid-rows-1 w-full md:w-3/4 mx-auto justify-center py-10">
                        
          <div className="col-start-1 row-start-1 z-0 justify-self-center">
            <StaticImage className="py-2" src={"../../../images/highlights/blogbgtrans.svg"} alt="underline" objectFit="contain" width={780} height={300} backgroundColor='transparent'/>
          </div>

          <DivStyled className="text-center col-start-1 row-start-1 z-40 flex flex-col justify-center text-pink-50 ">
            <p className="text-lg text-gray-800 font-bold">I am confident that you won't find similar material anywhere else on the internet and that you will truly understand ggplot2 from the ground up if you take this course. </p>
          </DivStyled>
        </div>

      </Container>
    </div>
  )
}

