import React from "react"
import { graphql } from 'gatsby'
import Shell from '../../components/global/shell'
import Hero from '../../components/courses/fundamentals/hero.js'

import CourseInfo from '../../components/courses/fundamentals/courseinfo.js'
import Audience from '../../components/courses/fundamentals/audience.js'
import Approach from '../../components/courses/fundamentals/approach.js'
import Curriculum from '../../components/courses/fundamentals/curriculum.js'
import Bonusmaterial from '../../components/courses/fundamentals/bonusmaterials.js'
import Register from '../../components/courses/fundamentals/register.js'
import Author from '../../components/courses/fundamentals/author.js'

import Helmet from "react-helmet"
import styled from 'styled-components'

const DivStyled = styled.div`
  h1, h2, h3 {
    font-family: "SF Fourche"
  }
`

const ggplot2Fundamentals = (props) => {

  
  return (
    <Shell>
      <Helmet>
        <title>ggplot2 Fundamentals</title>
      </Helmet>
      
      <DivStyled>
        <Hero />
        <CourseInfo />
        <Audience />
        {/* <Approach /> */}
        <Curriculum />
        <Bonusmaterial />
        <Register />
        <Author />
      </DivStyled>

    </Shell>
  )
}

export default ggplot2Fundamentals

