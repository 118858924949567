import React from 'react';
import Container from '../../global/container'
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"


export default function Bonusmaterial() {

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {absolutePath: {regex: "/cheatsheets/"}, extension: {regex: "/(jpg)|(png)|(jpeg)/"}}
      ) {
        edges {
          node {
            base
            absolutePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  `)

  const cheatsheets = data.allFile.edges;

  return (
    <div className="py-5 md:py-10">

      {/* Cheat sheets */}
      <Container>
        <h2 className="text-center"><span className="text-base font-bold text-gray-500">Packed with</span><br /><span className="text-2xl md:text-4xl"><span className="text-pink-500">Free</span> bonus material</span></h2>

        <div className='md:w-3/4 mx-auto'>
          
          <div className="grid grid-cols-1 grid-rows-1 w-full md:w-3/4 mx-auto justify-center pt-10 pb-5">
                        
            <div className="col-start-1 row-start-1 z-0 justify-self-center">
              <StaticImage className="py-2" src={"../../../images/highlights/blogbgtrans.svg"} alt="underline" objectFit="contain" width={700} height={280} placeholder='none'/>
            </div>

            <div className="text-center col-start-1 row-start-1 z-40 flex flex-col justify-center text-pink-800 ">
              <h3 className="text-center py-3 md:py-5 text-lg md:text-3xl">8 new cheat sheets</h3>
            </div>

          </div>

          <p className="text-base md:text-lg md:text-xl md:text-xl pt-5 text-gray-800 leading-relaxed w-full">To help you on your ggplot2 journey, I have created 8 brand-new cheat sheets that help you to grasp the most fundamental concepts. You won't find similar cheat sheets anywhere else on the internet  and best of all, they are free even if you don't sign up for the course.</p>
        </div>

        <span className='block text-center py-8 font-light text-base text-pink-800'>click <a href="https://github.com/ch-bu/ggplot2-fundamentals/tree/main/cheatsheets" className="font-bold" href="https://github.com/ch-bu/ggplot2-fundamentals/tree/main/cheatsheets" target="_blank">here</a> to download the cheat sheets</span>

        <div className="flex flex-row flex-wrap justify-center">
          {cheatsheets.map((e, i) => {
            return <GatsbyImage className="w-5/12 md:w-3/12 mb-5 mx-2 md:mx-5 shadow" image={e.node.childImageSharp.gatsbyImageData} 
              alt={e.node.base.split(".")[0]} />
          })}
        </div>

      </Container>

      {/* Web apps */}
      <Container>
        <div className="grid grid-cols-1 grid-rows-1 w-full md:w-3/4 mx-auto justify-center pt-10 pb-5">
                        
          <div className="col-start-1 row-start-1 z-0 justify-self-center">
            <StaticImage className="py-2" src={"../../../images/highlights/blogbgtrans.svg"} alt="underline" objectFit="contain" width={700} height={280} placeholder='none' />
          </div>

          <div className="text-center col-start-1 row-start-1 z-40 flex flex-col justify-center text-pink-800 ">
            <h3 className="text-center py-3 md:py-5 text-lg md:text-3xl">3 web apps</h3>
          </div>

          <p className="text-base md:text-lg md:text-xl md:text-xl pt-5 text-gray-800 leading-relaxed w-full">I also created 3 web apps which we will use in this course for three topics. Finding the right aesthetics for your geometric objects, findings the right scales for your aesthetic mappings and designing your plots with themes.</p>
        </div>


        <div className="flex flex-row flex-wrap justify-center mt-5">
          <Link to="/aesthetics"><StaticImage src="../../../images/apps/aesthetics.PNG" className=" mb-5 mx-2 md:mx-5 shadow"  height={200} width={320} alt="Aesthetics app" layout="fixed" objectFit="contain" /></Link>
          <Link to="/scales"><StaticImage src="../../../images/apps/scales.PNG" className=" mb-5 mx-2 md:mx-5  shadow" objectFit="contain" height={200} width={320} alt="Scales app" /></Link>
          <Link to="/theme"><StaticImage src="../../../images/apps/theme.PNG" className=" mb-5 mx-2 md:mx-5 shadow" objectFit="contain" height={230} width={320} alt="Theme app" /></Link>
        </div>

      </Container>
    </div>
  )
}
