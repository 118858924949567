import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Container from '../../global/container'
import styled from 'styled-components'


const DivStyled = styled.div`


  span{
    font-family: "SF Fourche"
  }

  span:nth-child(2) {
    font-family: "Open Sans"
  }
`

const courseInfo = [
  ["9", "modules", "../../../images/highlights/hexagon.svg"],
  ["110+", "videos", "../../../images/highlights/blob2.svg"],
  ["11+", "hours of video", "../../../images/highlights/blob2.svg"],
  ["8", "cheat sheets", "../../../images/highlights/blob2.svg"],
  ["3", "web apps", "../../../images/highlights/blob2.svg"],
  ["1", "GitHub repository"]
];


export default function CourseInfo() {
  return (
    <div className='py-10 md:py-20'> 
      <Container>
        <h2 className="text-center"><span className="text-base font-bold text-gray-700">Learn ggplot2</span><br /><span className="text-gray-900 text-2xl md:text-4xl mb-10 text-gray-900">In one place</span></h2>

        {/* <div className='md:w-3/4 mx-auto text-center pb-5 md:pb-10'>
          <StaticImage className="py-2" src="../../../images/highlights/hline2.svg" alt="underline" objectFit="contain" width={500} height={40} />
        </div> */}
        

        <div className='md:w-3/4 mx-auto'>
          <p className="text-base md:text-xl md:text-xl pt-5 text-gray-800 leading-relaxed w-full">ggplot2 has a great community of people who have created a huge amount of learning materials. Finding the "good stuff" and putting them into a coherent personal curriculum, however, is hard. What makes it even harder is that you won't find any materials for some of the most crucial concepts on the internet (e.g., aesthetics for geometric objects).</p>

          <p className="text-base md:text-xl md:text-xl pt-5 text-gray-800 leading-relaxed w-full">And there are many concepts to learn. You need to become familiar with data types, geometric objects, aesthetics, aesthetic mappings, faceting, scales, summary statistics, and themes. </p>

          <p className="text-base md:text-xl md:text-xl pt-5 text-gray-800 leading-relaxed w-full">This training course covers all these fundamental concepts of ggplot2 in one place!  Once you have taken this course you will be able to create almost any data visualization in ggplot2 with ease. Here is what you'll get from the course:</p>
        </div>

        </Container>

        <div className="py-12">
          <Container>
            <div className="flex flex-row flex-wrap md:w-3/4 mx-auto justify-between">
            {courseInfo.map((e, i) => {
                return (
                  <div className="grid grid-cols-1 grid-rows-1 w-32" key={i}>
                            
                    <div className="col-start-1 row-start-1 z-0">
                      <StaticImage className="py-2" src={"../../../images/highlights/hexagon.svg"} alt="underline" objectFit="contain" width={200} height={200} placeholder='none'/>
                    </div>
        
                    <DivStyled className="text-center col-start-1 row-start-1 z-40 flex flex-col justify-center align-center ">
                      <span className="text-2xl md:text-4xl text-pink-500">{e[0]}</span>
                      <span className="text-xs font-light text-pink-800">{e[1]}</span>
                    </DivStyled>
                  </div>
                )
              })}
            </div>
          </Container>
        </div>
        



      
    </div>
  )
}

