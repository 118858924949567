import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Container from '../../global/container'
import styled from 'styled-components'
import {GrFormCheckmark} from 'react-icons/gr'
import CourseCard from './coursecard.js'

const DivStyled = styled.div`
  margin-top: -40px;
`


export default function Register() {
  return (
    <div className='bg-gray-100 flex justify-center mt-20 pb-20'> 
      
      <DivStyled className="">
        <CourseCard />
      </DivStyled>
      
    </div>
  )
}

